'use client';

import { useEffect } from 'react';

import PageError from '@/components/PageError';
import { RootLayout } from '@/components/RootLayout';
import { captureException } from '@/utils/sentry';

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    captureException(error.message);
  }, [error]);

  return (
    <RootLayout>
      <PageError statusCode={500} />
    </RootLayout>
  );
}
